/* MODIFIED:  [ 2023-03-21 09:57:31 AM (OFFICE) ] */
/* Used for new Storefront */
//@config './tailwind_store.config.js';
/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html,
  body {
    scroll-behavior: smooth;
  }

  @import "../../_buttons.scss";
  @import "../../_inputs.scss";
  @import "../../cart/css/_tooltip.scss";
  @import "../../_flickity.scss";

  /* modal overlay */
  .overlay {
    @apply fixed inset-0 z-50 h-screen bg-gray-800/75 opacity-100 backdrop-blur-sm transition-opacity duration-200 dark:bg-gray-900/75;
  }

  /* checkout icons */
  .check-circle {
    @apply text-lime-600 dark:text-lime-500 h-8 w-8 flex-none pr-1;
  }
  .minus-circle {
    @apply h-8 w-8 flex-none pr-1 text-gray-300 dark:text-gray-500;
  }

  // ! PROSE links
  .prose-lg a {
    @apply text-primary-500 dark:text-primary-400;
  }

  // ! HEADERS ( used on Non-hero pages )
  .header-one {
    @apply text-primary-500 m-0 p-0 text-center text-5xl font-bold dark:text-primary-400;
  }

  .header-two {
    @apply mt-2 text-center text-2xl font-light text-gray-400 dark:text-gray-400;
  }
  // ! TOP MENU BUTTONS - Desktop
  .top-nav > a {
    @apply rounded-md bg-transparent px-3 py-2 text-base font-medium text-gray-500 transition duration-200 ease-in-out hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-300 dark:text-gray-400  dark:hover:bg-gray-800 dark:hover:text-gray-100 dark:focus:ring-primary-400;
  }
  .top-nav > a.active {
    @apply text-primary-500 pointer-events-none bg-primary-100 dark:bg-gray-800 dark:text-primary-300;
  }
  
  // ! SLIDE OUT MENU BUTTONS - Mobile
  .mobile-nav  a {
    @apply rounded-md bg-transparent text-xl font-medium text-gray-500 dark:text-gray-400;
  }
  .mobile-nav  a.active {
    @apply text-primary-500 pointer-events-none dark:text-primary-300;
  }

  // ! stylized radio inputs ( used on spinoffs )
  .spinoffs label {
    @apply block justify-center rounded rounded border-2 border-gray-200 bg-gray-100 px-4 py-2 text-center text-sm text-gray-400 transition duration-300 ease-in-out hover:border-gray-400 hover:bg-gray-200 dark:border-transparent dark:bg-gray-800 dark:hover:bg-gray-900;
  }
  .spinoffs input:checked ~ label {
    @apply text-primary-500 border-primary-500 dark:bg-primary-500 border-2 border-opacity-75 bg-primary-100 dark:text-white;
  }
}

/* was used on cookie banner in storefront, but can possibly use alpine params instead (see cart error/alert) */
/*Banner open/load animation*/
.alert-banner {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Banner close animation*/
.alert-banner input:checked ~ * {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/*Footer open/load animation*/
.alert-footer {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Footer close animation*/
.alert-footer input:checked ~ * {
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* -------------------------------------------------------------
 * Animations generated using Animista * w: http://animista.net, 
 * Used in Cookie Warning! - DON'T DELETE
 * ---------------------------------------------------------- */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
