/* MODIFIED:  [ 2023-03-21 09:57:31 AM (OFFICE) ] */
/* Used for new Storefront */
/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  html,
  body {
    scroll-behavior: smooth;
  }
  .btn-primary,
  .button-primary {
    @apply focus:ring-primary-500 dark:hover:border-primary-500 dark:hover:ring-primary-500/25 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-primary-700 hover:ring-4 hover:ring-primary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-700 sm:w-auto sm:text-base;
  }
  .button-default,
  .btn-default,
  .btn-secondary {
    @apply inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white
	px-4 py-3 text-base
	font-normal leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700
	hover:ring-2
	hover:ring-gray-200 focus:outline-none active:bg-gray-50
	dark:border-gray-600/50 dark:bg-gray-800/50 dark:text-gray-200
	dark:hover:bg-gray-800 dark:hover:text-white dark:hover:ring-gray-500 sm:mt-0 sm:w-auto;
  }
  .button-link {
    @apply text-primary-500 mt-2 flex w-full items-center justify-center bg-white px-4
	py-2 text-sm font-normal
	leading-6 transition duration-300 ease-in-out
	hover:text-primary-700
 focus:outline-none sm:mt-0 sm:w-auto;
  }
  .button-primary-500 {
    @apply border-primary-500 bg-primary-500 flex w-full items-center justify-center rounded-md border px-4 py-3
	text-base font-semibold
	leading-6 text-white transition duration-300 ease-in-out hover:bg-primary-700 hover:ring-4
	hover:ring-primary-200
	focus:outline-none active:bg-primary-700
	sm:w-auto sm:py-2 sm:text-sm;
  }
  .btn-delete {
    @apply flex items-center justify-center rounded-md border-2 border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 hover:ring-4 hover:ring-red-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 dark:bg-red-400 dark:hover:border-red-500 dark:hover:bg-red-800 dark:hover:ring-red-500/25 dark:focus:ring-offset-gray-700;
  }
  /* INPUT CSS FOR ALL THINGS CARTLOOM  */
  /* MODIFIED:  [ 2024-07-16 03:36:51 PM (OFFICE) ] */
  .input-group,
  .radio-group,
  .lang-group,
  .cb-group {
    @apply relative transition-all;
  }
  .input-btn-group {
    @apply relative;
  }
  .input-group input::placeholder,
  .input-group textarea::placeholder,
  .input-btn-group input::placeholder {
    @apply font-sans text-gray-500;
  }
  /* Checkboxes and Radios */
  .cb-group input[type=checkbox] {
    @apply focus:ring-primary-500 h-5 w-5 rounded border-gray-300 text-primary-600 transition dark:border-gray-500 dark:bg-gray-900/50 dark:ring-offset-gray-800;
  }
  .radio-group input[type=radio] {
    @apply focus:ring-primary-500 h-5 w-5 rounded-full border-gray-300 text-primary-600 transition dark:border-gray-500 dark:bg-gray-900/50 dark:ring-offset-gray-800;
  }
  .radio-group input[type=radio]:checked,
  .cb-group input[type=checkbox]:checked {
    @apply border-primary-500 bg-primary-500 ring-primary-600/25 ring-2 dark:border-primary-400;
  }
  .cb-group > label,
  .radio-group > label {
    @apply my-3 font-semibold;
  }
  .cb-group .cb-option,
  .radio-group .cb-option {
    @apply mr-4 mt-1 inline-block w-full;
  }
  .cb-group .cb-option label,
  .radio-group .cb-option label {
    @apply flex items-center text-sm;
  }
  .cb-group .cb-option span,
  .radio-group .cb-option span {
    @apply ml-2 text-gray-500 dark:text-gray-200;
  }
  .input-group input[type=text],
  .input-group input[type=email],
  .input-group input[type=number],
  .input-group input[type=password] {
    @apply focus:border-primary-500 focus:ring-primary-600/25 dark:focus:ring-primary-500/25 dark:focus:ring-offset-primary-500 m-0 h-auto min-h-[50px] w-full transform rounded-md border border-gray-300 bg-white object-center p-3 font-medium text-gray-800 outline-none duration-150 ease-in-out focus:shadow-none focus:outline-none focus:ring-4 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-offset-2;
  }
  /* used for language settings */
  .lang-group input[type=text] {
    @apply focus:border-primary-500 focus:ring-primary-600/25 dark:focus:ring-primary-500/50 dark:focus:ring-offset-primary-500/25 m-0 h-auto min-h-[50px] w-full transform truncate rounded-md border border-gray-300 bg-white object-center pt-6 font-normal text-gray-800 outline-none duration-150 ease-in-out placeholder:text-gray-500 focus:shadow-none focus:outline-none focus:ring-4 dark:border-transparent dark:bg-gray-800 dark:text-white dark:placeholder:text-gray-500 dark:focus:border-primary-300;
  }
  /* Dropdown / Autoomplete (popup menu) */
  .input-group.dropdown button {
    @apply focus:border-primary-500 focus:border-primary-500 focus:ring-primary-600/25 dark:focus:ring-primary-500/25 dark:focus:ring-offset-primary-500 inline-flex h-[50px] w-full transform items-center justify-between rounded-md border border-gray-300 bg-white p-3 pb-1 pl-3 pt-5 font-medium duration-150 ease-in-out focus:shadow-none focus:outline-none focus:ring-4 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-offset-2;
  }
  .input-group.dropdown .menu,
  .input-group.autocomplete .menu {
    @apply absolute left-0 z-10 mt-2 w-full origin-top-left rounded-md border border-gray-400 bg-white/90 py-2 shadow-md backdrop-blur-sm focus:outline-none dark:border-gray-500 dark:bg-gray-900/90 dark:shadow-none;
  }
  .input-group.dropdown .menu a,
  .input-group.autocomplete .menu a {
    @apply block px-4 py-0.5 font-medium text-gray-500 hover:bg-primary-100 hover:text-primary-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white;
  }
  /* stepper for number inputs */
  .input-group .stepper {
    @apply absolute bottom-0 right-0 top-0 flex w-8 flex-col items-center justify-center rounded-r border border-gray-300 bg-white dark:border-gray-700 dark:bg-gray-900/50;
  }
  .input-btn-group input[type=text],
  .input-btn-group input[type=email],
  .input-btn-group input[type=password] {
    @apply m-0 h-auto w-full transform rounded-none rounded-l-md border border-gray-300 bg-transparent bg-white object-center p-3 text-sm text-gray-800 shadow-none outline-none duration-150 ease-in-out;
  }
  .input-group.number input {
    @apply font-medium;
  }
  /* Textarea */
  .input-group textarea {
    @apply focus:border-primary-500 focus:ring-primary-600/25 dark:focus:ring-primary-500/25 dark:focus:ring-offset-primary-500 m-0 w-full transform rounded-md border border-gray-300 bg-transparent bg-white object-center p-0 pl-3 pt-3 align-middle text-sm font-medium leading-6 text-gray-800 duration-150 ease-in-out focus:shadow-none focus:outline-none focus:ring-4 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-offset-2;
  }
  /* Select Menu */
  .input-group select,
  button.pseudo-select {
    @apply focus:border-primary-500 focus:ring-primary-600/25 dark:focus:ring-primary-500/25 dark:focus:ring-offset-primary-500 h-[50px] w-full transform rounded-md border-gray-300 bg-white p-3 pb-1 pl-3 pt-5 font-sans font-medium duration-150 ease-in-out focus:shadow-none focus:outline-none focus:ring-4 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-offset-2;
  }
  option {
    @apply font-sans;
  }
  .input-group label,
  .input-btn-group label {
    @apply absolute left-0 top-0 block pl-3 pt-3 text-xs font-semibold text-primary-700 opacity-0 transition-opacity duration-150 ease-in dark:text-primary-300;
  }
  .lang-group label {
    @apply absolute left-0 top-2 block pl-3 text-xs font-semibold text-primary-700 dark:text-primary-300;
  }
  .input-group textarea ~ label {
    @apply absolute left-2 top-4 rounded-full bg-white p-1 dark:bg-gray-800;
  }
  .input-btn-group ~ button {
    @apply relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-white hover:text-gray-500 focus:border-primary-300 focus:outline-none active:bg-gray-100 active:text-gray-700;
  }
  .input-group select.form-select,
  button.pseudo-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23737373' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M8 9l4-4 4 4m0 6l-4 4-4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.25em 1.25em;
    padding-right: 2.5rem;
    print-color-adjust: exact;
  }
  /* firefox fixes to remove red borders and focus */
  .input-group input[type=text]:required,
  .input-group input[type=email]:required,
  .input-group input[type=password]:required,
  .input-group input[type=number]:required {
    @apply shadow-none;
  }
  .input-group input[type=text]:invalid,
  .input-group input[type=email]:invalid,
  .input-group input[type=password]:invalid,
  .input-group input[type=number]:invalid {
    @apply shadow-none outline-none;
  }
  /* // animated effect when typing starts ( and placeholder is gone ) */
  /* //.input-group input:focus-within, */
  .input-group input:not(:placeholder-shown),
  .input-btn-group input:not(:placeholder-shown) {
    @apply p-4 pb-1 pl-3 pt-5;
  }
  .input-btn-group.pre-icon input:not(:placeholder-shown) {
    @apply p-4 pb-1 pl-10 pt-6;
  }
  .input-group input:not(:placeholder-shown) ~ .pre-icon,
  .input-btn-group input:not(:placeholder-shown) ~ .pre-icon {
    @apply p-4 pb-1 pl-3 pt-6 text-sm;
  }
  .input-group textarea:not(:placeholder-shown) {
    @apply p-4 pb-1 pl-3 pt-8;
  }
  /* //.input-group input:focus-within ~ label, */
  .input-group input:not(:placeholder-shown) ~ label,
  .input-group textarea:not(:placeholder-shown) ~ label,
  .input-group select ~ label,
  .input-group button ~ label,
  button.pseudo-select ~ label,
  .input-btn-group input:not(:placeholder-shown) ~ label,
  .input-btn-group textarea:not(:placeholder-shown) ~ label {
    @apply -translate-y-1 opacity-100 transition-opacity ease-in;
  }
  .input-group.error input[type=text],
  .input-group.error input[type=email],
  .input-group.error input[type=number],
  .input-btn-group.error input[type=text],
  .input-group.error input[type=password],
  .input-group.error textarea,
  .input-group.error select {
    @apply border-2 border-red-300 text-red-500 dark:text-red-400 dark:border-red-500/50
	focus:border-red-300 dark:focus:border-red-500 focus:outline-none;
  }
  .input-group.error label {
    @apply text-red-400;
  }
  /* disable inputs during processing */
  .processing .input-group,
  .processing .input-btn-group {
    @apply pointer-events-none opacity-50;
  }
  .input-group.error input::placeholder,
  .input-group.error textarea::placeholder {
    @apply text-red-400 opacity-100;
  }
  .tooltip svg {
    @apply text-gray-300 transition duration-100 ease-in-out hover:text-gray-400 dark:text-gray-600 dark:hover:text-gray-500;
  }
  .input-group .tooltip {
    @apply absolute right-0 top-0 mr-3 flex items-center;
  }
  /* this one has gradient background */
  .input-group input[type=text] ~ .tooltip,
  .input-group input[type=password] ~ .tooltip {
    @apply absolute bottom-1 right-1 top-1 flex max-h-[42px] items-center;
  }
  .input-group.dropdown .tooltip {
    @apply top-4 mr-7;
  }
  .input-group textarea ~ .tooltip {
    @apply top-4;
  }
  .input-group.number .tooltip {
    @apply right-6 top-4;
  }
  .input-group.error .tooltip {
    @apply pointer-events-none absolute inset-y-1 right-0 mr-3 hidden items-center;
  }
  .input-group .error-icon,
  .input-group .success-icon {
    @apply pointer-events-none absolute inset-y-1 right-0 mr-4 hidden items-center;
  }
  .input-group .clear-icon {
    @apply pointer-events-none absolute inset-y-0 right-0 mr-3 flex hidden items-center text-gray-300 transition dark:text-gray-600 dark:hover:text-gray-500;
  }
  .input-group.error .error-icon {
    @apply pointer-events-none absolute inset-y-0 right-0 mr-4 flex items-center;
  }
  #summary-col .input-group.error .error-icon {
    @apply mr-8;
  }
  .input-group.success .success-icon {
    @apply pointer-events-none absolute inset-y-0 right-0 mr-8 flex items-center;
  }
  .input-group.success .clear-icon {
    @apply pointer-events-auto absolute inset-y-0 right-0 mr-3 flex cursor-pointer items-center hover:text-gray-500;
  }
  .input-group input:not(:placeholder-shown) ~ .clear-icon {
    @apply pointer-events-auto absolute inset-y-0 right-0 mr-3 flex cursor-pointer items-center hover:text-gray-500;
  }
  /*
  .input-group.success input[type='text'],
  .input-group.success input[type='email'] {
  	@apply border-2 border-primary-500 ring-2 ring-primary-200
  }
  */
  .radio-group.error .form-radio,
  .cb-group.error .form-checkbox,
  .accept-terms.error .slide-toggle {
    @apply border-red-400;
  }
  .radio-group.error .error-icon,
  .cb-group.error .error-icon,
  .accept-terms.error .error-icon {
    @apply pointer-events-none relative mr-2 inline flex items-center;
  }
  .radio-group .error-msg,
  .cb-group .error-msg,
  .accept-terms .error-msg,
  .accept-terms .error-icon {
    @apply hidden;
  }
  .radio-group.error .error-msg,
  .cb-group.error .error-msg,
  .accept-terms.error .error-msg {
    @apply block;
  }
  .input-group .error-msg {
    @apply mt-1 hidden truncate text-xs text-red-500 dark:text-red-400;
  }
  .input-group.error .error-msg {
    @apply ml-2 mt-1 block truncate text-xs text-red-500 dark:text-red-400;
  }
  .input-group .success-msg {
    @apply mt-1 hidden truncate text-xs text-primary-600 transition;
  }
  .input-group.success .success-msg {
    @apply mt-1 block truncate text-xs text-primary-600 transition;
  }
  /* // apply button groups used in sidebar at checkout
  // discount, vat, gift card etc... */
  .apply-btn-group {
    @apply mt-1 flex w-auto flex-wrap space-x-1;
  }
  .apply-btn-group .label {
    @apply truncate text-sm leading-normal text-gray-600 transition duration-500 ease-in-out hover:text-gray-800 dark:text-gray-500 dark:hover:text-gray-200;
  }
  .apply-btn-group .psx,
  .apply-btn-group.processing .label {
    @apply hidden;
  }
  .apply-btn-group.processing .psx,
  .apply-btn-group .label {
    @apply block;
  }
  .apply-btn-group button {
    @apply h-[50px] w-20 rounded-md border border-gray-300 bg-gray-50 object-center py-3 text-center text-gray-400 transition duration-500 ease-in-out hover:bg-white focus:outline-none dark:border-transparent dark:bg-gray-800/75 dark:hover:bg-gray-800;
  }
  /* tippy css */
  .tippy-box[data-animation=fade][data-state=hidden] {
    @apply opacity-0;
  }
  [data-tippy-root] {
    max-width: calc(100vw - 10px);
  }
  .tippy-box {
    @apply relative whitespace-normal rounded-md bg-gray-700 text-sm text-white outline-none transition dark:bg-primary-500;
  }
  .tippy-box[data-placement^=top] > .tippy-arrow {
    @apply bottom-0;
  }
  .tippy-box[data-placement^=top] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top;
  }
  .tippy-box[data-placement^=bottom] > .tippy-arrow {
    @apply top-0;
  }
  .tippy-box[data-placement^=bottom] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom;
  }
  .tippy-box[data-placement^=left] > .tippy-arrow {
    @apply right-0;
  }
  .tippy-box[data-placement^=left] > .tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left;
  }
  .tippy-box[data-placement^=right] > .tippy-arrow {
    left: 0;
  }
  .tippy-box[data-placement^=right] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right;
  }
  .tippy-box[data-inertia][data-state=visible] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }
  .tippy-arrow {
    @apply h-[16px] w-[16px] text-gray-800 before:absolute before:border-solid before:border-transparent dark:text-primary-500;
  }
  .tippy-content {
    @apply relative z-10 py-2 px-6;
  }
  /*! Flickity v2.3.0
  https://flickity.metafizzy.co
  ---------------------------------------------- */
  .flickity-enabled {
    position: relative;
  }
  .flickity-enabled:focus {
    outline: none;
  }
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  /* draggable */
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  /* ---- flickity-button ---- */
  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }
  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19F;
  }
  .flickity-button:active {
    opacity: 0.6;
  }
  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  .flickity-button-icon {
    fill: currentColor;
  }
  /* ---- previous/next buttons ---- */
  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  /* ---- page dots ---- */
  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }
  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }
  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
  }
  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }
  /* Flickity fullscreen v1.0.1
  ------------------------- */
  .flickity-enabled.is-fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.9);
    padding-bottom: 35px;
    z-index: 1;
  }
  .flickity-enabled.is-fullscreen .flickity-page-dots {
    bottom: 10px;
  }
  .flickity-enabled.is-fullscreen .flickity-page-dots .dot {
    background: white;
  }
  /* prevent page scrolling when flickity is fullscreen */
  html.is-flickity-fullscreen {
    overflow: hidden;
  }
  /* ---- flickity-fullscreen-button ---- */
  .flickity-fullscreen-button {
    display: block;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
  /* right-to-left */
  .flickity-rtl .flickity-fullscreen-button {
    right: auto;
    left: 10px;
  }
  .flickity-fullscreen-button-exit {
    display: none;
  }
  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit {
    display: block;
  }
  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-view {
    display: none;
  }
  .flickity-fullscreen-button .flickity-button-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 4px;
    top: 4px;
  }
  /* modal overlay */
  .overlay {
    @apply fixed inset-0 z-50 h-screen bg-gray-800/75 opacity-100 backdrop-blur-sm transition-opacity duration-200 dark:bg-gray-900/75;
  }
  /* checkout icons */
  .check-circle {
    @apply text-lime-600 dark:text-lime-500 h-8 w-8 flex-none pr-1;
  }
  .minus-circle {
    @apply h-8 w-8 flex-none pr-1 text-gray-300 dark:text-gray-500;
  }
  .prose-lg a {
    @apply text-primary-500 dark:text-primary-400;
  }
  .header-one {
    @apply text-primary-500 m-0 p-0 text-center text-5xl font-bold dark:text-primary-400;
  }
  .header-two {
    @apply mt-2 text-center text-2xl font-light text-gray-400 dark:text-gray-400;
  }
  .top-nav > a {
    @apply rounded-md bg-transparent px-3 py-2 text-base font-medium text-gray-500 transition duration-200 ease-in-out hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-300 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-100 dark:focus:ring-primary-400;
  }
  .top-nav > a.active {
    @apply text-primary-500 pointer-events-none bg-primary-100 dark:bg-gray-800 dark:text-primary-300;
  }
  .mobile-nav a {
    @apply rounded-md bg-transparent text-xl font-medium text-gray-500 dark:text-gray-400;
  }
  .mobile-nav a.active {
    @apply text-primary-500 pointer-events-none dark:text-primary-300;
  }
  .spinoffs label {
    @apply block justify-center rounded rounded border-2 border-gray-200 bg-gray-100 px-4 py-2 text-center text-sm text-gray-400 transition duration-300 ease-in-out hover:border-gray-400 hover:bg-gray-200 dark:border-transparent dark:bg-gray-800 dark:hover:bg-gray-900;
  }
  .spinoffs input:checked ~ label {
    @apply text-primary-500 border-primary-500 dark:bg-primary-500 border-2 border-opacity-75 bg-primary-100 dark:text-white;
  }
}
/* was used on cookie banner in storefront, but can possibly use alpine params instead (see cart error/alert) */
/*Banner open/load animation*/
.alert-banner {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Banner close animation*/
.alert-banner input:checked ~ * {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/*Footer open/load animation*/
.alert-footer {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Footer close animation*/
.alert-footer input:checked ~ * {
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* -------------------------------------------------------------
 * Animations generated using Animista * w: http://animista.net, 
 * Used in Cookie Warning! - DON'T DELETE
 * ---------------------------------------------------------- */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}