/* tippy css */

.tippy-box[data-animation="fade"][data-state="hidden"] {
  @apply opacity-0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  @apply relative whitespace-normal rounded-md bg-gray-700 text-sm text-white outline-none transition dark:bg-primary-500;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  @apply bottom-0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  @apply top-0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  @apply right-0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  @apply h-[16px] w-[16px] text-gray-800 before:absolute before:border-solid before:border-transparent dark:text-primary-500;
}

.tippy-content {
  @apply relative z-10 py-2 px-6;
}
