.btn-primary,
.button-primary {
  @apply focus:ring-primary-500 dark:hover:border-primary-500 dark:hover:ring-primary-500/25 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-primary-700 hover:ring-4 hover:ring-primary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-700 sm:w-auto sm:text-base;
}

// @apply border-primary-500 bg-primary-500 flex w-full items-center justify-center rounded-md border
// p-4  text-base font-medium
// leading-6 text-white ring-opacity-50 transition duration-150 ease-in-out hover:bg-primary-700
// hover:ring-2 hover:ring-primary-200
// focus:outline-none active:bg-primary-700 dark:border-gray-900/50
// dark:hover:ring-primary-400 sm:w-auto sm:py-2;

.button-default,
.btn-default,
.btn-secondary {
  @apply inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white  
	px-4 py-3  text-base 
	font-normal leading-6 text-gray-500 transition duration-150  ease-in-out  hover:text-gray-700
	hover:ring-2 
	hover:ring-gray-200 focus:outline-none active:bg-gray-50
	dark:border-gray-600/50 dark:bg-gray-800/50 dark:text-gray-200
	dark:hover:bg-gray-800 dark:hover:text-white dark:hover:ring-gray-500 sm:mt-0 sm:w-auto;
}

.button-link {
  @apply text-primary-500 mt-2 flex w-full items-center justify-center bg-white px-4
	py-2 text-sm font-normal
	leading-6 transition duration-300  ease-in-out
	hover:text-primary-700
 focus:outline-none sm:mt-0 sm:w-auto;
}

.button-primary-500 {
  @apply border-primary-500 bg-primary-500 flex w-full items-center justify-center rounded-md border px-4 py-3
	text-base font-semibold
	leading-6 text-white transition duration-300 ease-in-out hover:bg-primary-700 hover:ring-4
	hover:ring-primary-200
	focus:outline-none active:bg-primary-700
	sm:w-auto sm:py-2 sm:text-sm;
}

.btn-delete {
  @apply flex items-center justify-center rounded-md border-2 border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 hover:ring-4 hover:ring-red-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 dark:bg-red-400 dark:hover:border-red-500 dark:hover:bg-red-800 dark:hover:ring-red-500/25 dark:focus:ring-offset-gray-700;
}
